import styles from './DashboardLayout.module.less';
import {Link, useNavigate} from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext";
import { Routes } from 'constants/routes';
import { Header, Footer } from 'components';
import Sidebar from 'components/Sidebar';
import SvgIcon from 'components/SvgIcon';

const LoginLayout = ({ children }) => {

    const {
        state,
        actions: { clearAuthData },
    } = useAuthContext();

    const navigate = useNavigate();

    const HandleLogout = (event) => {
        clearAuthData();

        navigate(Routes.LOGIN);
    }

    return (
        <>
            <Header />
            <main>
                <Sidebar />
                <div className={styles.dashboardMainBlock}>
                    <div className={styles.dashboardTopBlock}>
                        <div className="search">
                            <SvgIcon id="icon-search" className="icon" />
                            <input type="text" maxLength="255" className="rounded" placeholder="Search"/>
                        </div>
                        <div className="actions">
                            <Link to={Routes.REVIEWS} className="action action-help icon">
                                <SvgIcon id="icon-help" className="icon" />
                            </Link>
                            <Link to={Routes.REVIEWS} className="action action-notification icon">
                                <SvgIcon id="icon-notification" className="icon" />
                            </Link>
                            <Link to={Routes.REVIEWS} className="action action-setting icon">
                                <SvgIcon id="icon-setting" className="icon" />
                            </Link>
                            <div className="button mini rounded grey logout" onClick={HandleLogout}>
                                <SvgIcon id="icon-logout" className="icon" />
                                <span>Logout</span>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </main>
            <Footer />
        </>
    )
}

export default LoginLayout;