import './Sidebar.less';
import {Link} from "react-router-dom";
import SvgIcon from 'components/SvgIcon';
import {Routes} from 'constants/routes';
import {useTranslation} from "react-i18next";
import {useLocation} from 'react-router-dom';
import {useState,useEffect} from 'react';

const Sidebar = (props) => {
    const { t } = useTranslation();

    const location = useLocation(); // React Hook
    const [menuActive, setMenuActive] = useState(location.pathname);

    useEffect(() => {
        var pathName = location.pathname.split('/');
        setMenuActive(pathName[1]);
    }, [location]);

    return (
        <div className="sidebar">
            <div className="hello">
                <div className="avatar">
                    <SvgIcon id="icon-customer-white" className="svg" />
                </div>
                <div className="text">Hello, <span>Ilya!</span></div>
            </div>
            <div className="location rounded animate">
                <SvgIcon id="icon-location" className="svg" />
                <span>Warsaw</span>
                <SvgIcon id="icon-change" className="svg-change" />
            </div>
            <div className="top-actions">
                <Link to={Routes.ORDERS} className="button rounded animate middle blue full-width button-add-new-order">
                    <SvgIcon id="icon-add-white" className="svg" />
                    <span>{t('Add new order')}</span>
                </Link>
            </div>
            <menu className="main-menu">
                <li className="dashboard">
                    <Link to={Routes.DASHBOARD} className={menuActive === 'dashboard' ? "top-link rounded active" : "top-link rounded"}>
                        <SvgIcon id="icon-dashboard" className="svg" />
                        <span>{t('Main dashboard')}</span>
                    </Link>
                </li>
                <li className="orders">
                    <Link to={Routes.ORDERS} className={menuActive === 'orders' ? "top-link rounded active" : "top-link rounded"}>
                        <SvgIcon id="icon-cart" className="svg" />
                        <span>{t('Orders')}</span>
                    </Link>
                </li>
                <li className="partners">
                    <Link to={Routes.PARTNERS} className={menuActive === 'partners' ? "top-link rounded active" : "top-link rounded"}>
                        <SvgIcon id="icon-partner" className="svg" />
                        <span>{t('Partners')}</span>
                    </Link>
                </li>
                <li className="customers">
                    <Link to={Routes.CUSTOMERS} className={menuActive === 'customers' ? "top-link rounded active" : "top-link rounded"}>
                        <SvgIcon id="icon-partner" className="svg" />
                        <span>{t('Customers')}</span>
                    </Link>
                </li>
                <li className="customers">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-review" className="svg" />
                        <span>{t('Reviews')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-discount" className="svg" />
                        <span>{t('Promocodes')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-box" className="svg" />
                        <span>{t('Warehouse')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-message" className="svg" />
                        <span>{t('Messages')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-gift" className="svg" />
                        <span>{t('Certificates')}</span>
                    </Link>
                </li>
            </menu>
            <menu className="sub-menu">
                <li className="customers">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-plot" className="svg" />
                        <span>{t('Analytics')}</span>
                    </Link>
                </li>
                <li className="customers">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-price" className="svg" />
                        <span>{t('Services')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-learning" className="svg" />
                        <span>{t('Learning')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-document" className="svg" />
                        <span>{t('Documents')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-question" className="svg" />
                        <span>{t('Q&A')}</span>
                    </Link>
                </li>
                <li className="promocodes">
                    <Link to={Routes.ORDERS} className="top-link rounded">
                        <SvgIcon id="icon-target" className="svg" />
                        <span>{t('SEO')}</span>
                    </Link>
                </li>
            </menu>
        </div>
    );
};

export default Sidebar;