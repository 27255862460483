import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout/index.jsx';
import LoginLayout from 'layouts/LoginLayout/index.jsx';
import DashboardLayout from 'layouts/DashboardLayout/index.jsx';

import AuthProvider from "contexts/AuthContext";
import PrivateRoute from "hooks/privateRoute";
import { Routes } from 'constants/routes';

const LoginPage = lazy(() => import('../pages/LoginPage'));
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const CustomersPage = lazy(() => import('../pages/CustomersPage'));
const OrdersPage = lazy(() => import('../pages/OrdersPage'));
const PartnersPage = lazy(() => import('../pages/PartnersPage'));
const FrontendPage = lazy(() => import('../pages/FrontendPage'));
const PartnerDetailsPage = lazy(() => import('../pages/PartnerDetailsPage'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthProvider><MainLayout /></AuthProvider>,
        children: [
            {
                index: true,
                element: <FrontendPage />,
            },
            {
                path: Routes.LOGIN,
                element: <LoginLayout><LoginPage /></LoginLayout>,
            },
            {
                path: '/',
                element: <DashboardLayout><PrivateRoute /></DashboardLayout>,
                children: [
                    {
                        path: Routes.DASHBOARD,
                        element: <DashboardPage />,
                    },
                    {
                        path: '/customers',
                        element: <CustomersPage />,
                    },
                    {
                        path: '/orders',
                        element: <OrdersPage />,
                    },
                    {
                        path: Routes.PARTNERS,
                        children: [
                            {
                                index: true,
                                element: <PartnersPage />,
                            },
                            {
                                path: ':page',
                                element: <PartnersPage />,
                            },
                            {
                                path: 'details',
                                children: [
                                    { path: ':id', element: <PartnerDetailsPage /> }
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

export default router;
