import './Header.less';

const Header = () => {
    //const isMobile = useMediaQuery('(max-width: 390px)');

    return (
        <header className="header"></header>
    );
};

export default Header;
