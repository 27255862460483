import './Footer.less';

const Footer = () => {
    return (
        <footer className="footer">
        </footer>
    );
};

export default Footer;
