import { actionAuthTypes } from 'constants/actionAuthTypes';

export const initialState = {
    id: localStorage.getItem('authId'),
    name: localStorage.getItem('authName'),
    token: localStorage.getItem('authToken'),
    email: localStorage.getItem('authEmail'),
    isLogged: localStorage.getItem('authIsLogged'),
    language: localStorage.getItem('authLanguage'),
    location: localStorage.getItem('authLocation'),
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case actionAuthTypes.SET_AUTH_DATA:
            localStorage.setItem("authId", action.payload.id);
            localStorage.setItem("authName", action.payload.name);
            localStorage.setItem("authToken", action.payload.token);
            localStorage.setItem("authEmail", action.payload.email);
            localStorage.setItem("authLanguage", action.payload.language);
            localStorage.setItem("authLocation", action.payload.location);
            localStorage.setItem("authIsLogged", action.payload.isLogged);

            return {
                id: action.payload.id,
                name: action.payload.name,
                token: action.payload.token,
                email: action.payload.email,
                isLogged: action.payload.isLogged,
                language: action.payload.language,
                location: action.payload.location,
            }
        case actionAuthTypes.CLEAR_AUTH_DATA:
            localStorage.clear();

            return {
                id: false,
                name: false,
                token: false,
                email: false,
                isLogged: false,
                language: false,
                location: false,
            }
        default:
            return state;
    }
};
