import {useContext, createContext, useState, useReducer, useMemo} from "react";

import { initialState, authReducer } from './reducers/AuthReducer';
import useAuthActions from 'hooks/useAuthActions';
import {useTranslation} from "react-i18next";

const AuthContext = createContext({
    state: initialState,
    actions: {},
});

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const actions = useAuthActions(dispatch);
    const value = useMemo(() => ({ state, actions }), [state, actions]);
    console.log('🚀 ~ AuthProvider ~ value:', value);

    const {t, i18n} = useTranslation();

    // change language
    if(i18n.language !== state.language){
        i18n.changeLanguage(state.language);
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuthContext = () => {
    return useContext(AuthContext);
};