import styles from './HeaderLogin.module.less';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { Routes } from 'constants/routes';
import SvgIcon from 'components/SvgIcon';

const Header = () => {
    //const isMobile = useMediaQuery('(max-width: 390px)');

    const { t } = useTranslation();

    return (
        <section className={styles.headerLogin}>
            <Link to={Routes.HOME} className="logo">
                <SvgIcon id="icon-logo" className="svg-logo" />
            </Link>
            <div className="rightBlock">
                <menu
                    className="nav-list"
                    itemScope
                    itemType="http://schema.org/SiteNavigationElement">
                    <li itemProp="name">
                        <Link to={Routes.REVIEWS} className="opinion">
                            {t('Product')}
                        </Link>
                    </li>
                    <li itemProp="name">
                        <Link to={Routes.REVIEWS} className="opinion">
                            {t('Industries')}
                        </Link>
                    </li>
                    <li itemProp="name">
                        <Link to={Routes.REVIEWS} className="opinion">
                            {t('Pricing')}
                        </Link>
                    </li>
                    <li itemProp="name">
                        <Link to={Routes.REVIEWS} className="opinion">
                            {t('Cases')}
                        </Link>
                    </li>
                    <li itemProp="name">
                        <Link to={Routes.REVIEWS} className="opinion">
                            {t('Resources')}
                        </Link>
                    </li>
                    <li itemProp="name">
                        <Link to={Routes.REVIEWS} className="opinion">
                            {t('Customer Support')}
                        </Link>
                    </li>
                </menu>
                <div className="navigation">
                    <Link to={Routes.LOGIN} className="account animate rounded-full">
                        <SvgIcon id="icon-account" className="svg-logo" />
                        <label>{t('Account')}</label>
                    </Link>
                    <Link to={Routes.LOGIN} className="try-service animate rounded-full">
                        <SvgIcon id="icon-try" className="svg-logo" />
                        <label>{t('Try free demo')}</label>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Header;