import { Suspense } from 'react';
import {Outlet} from "react-router-dom";

import HeaderLogin from 'components/Header/HeaderLogin';
import FooterLogin from 'components/Footer/FooterLogin';

const LoginLayout = ({ children }) => {
    return (
        <>
            <HeaderLogin />
            <main>
                <Suspense>
                    <Outlet />
                    {children}
                </Suspense>
            </main>
            <FooterLogin />
        </>
    )
}

export default LoginLayout;