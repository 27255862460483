import styles from './FooterLogin.module.less';
import {Link} from "react-router-dom";
import { Routes } from 'constants/routes';
import SvgIcon from 'components/SvgIcon';

const Footer = () => {
    return (
        <>
            <section className={styles.footerLoginPage}>
                <div className="top-block">
                    <div className="left-block">
                        <Link to={Routes.HOME} className="logo">
                            <SvgIcon id="icon-logo-black" className="svg-logo" />
                        </Link>
                    </div>
                    <div className="right-block">
                        <div className="social-networks">
                            <Link to={Routes.HOME} className="youtube">
                                <SvgIcon id="icon-youtube" className="svg-logo" />
                            </Link>
                            <Link to={Routes.HOME} className="facebook">
                                <SvgIcon id="icon-facebook" className="svg-logo" />
                            </Link>
                            <Link to={Routes.HOME} className="telegram">
                                <SvgIcon id="icon-telegram" className="svg-logo" />
                            </Link>
                            <Link to={Routes.HOME} className="twitter">
                                <SvgIcon id="icon-twitter" className="svg-logo" />
                            </Link>
                            <Link to={Routes.HOME} className="instagram">
                                <SvgIcon id="icon-instagram" className="svg-logo" />
                            </Link>
                            <Link to={Routes.HOME} className="flickr">
                                <SvgIcon id="icon-flickr" className="svg-logo" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="bottom-block">
                    <div className="left-block">
                        <div className="location">
                            <SvgIcon id="icon-location" className="svg-logo" />
                            <span>Poland</span>
                        </div>
                        <div className="language">
                            <SvgIcon id="icon-language" className="svg-logo" />
                            <span>English (United States)</span>
                        </div>
                    </div>
                    <div className="right-block">
                        <div className="bottom-menu">
                            <ul>
                                <li>
                                    <Link to={Routes.HOME} className="link top-link">
                                        Products
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Reviews
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Payments
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Webchat
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Channels
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Contacts
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Pricing
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Inbox
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Text Campaigns
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to={Routes.HOME} className="link top-link">
                                        Resources
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Google Reviews
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Online Reviews
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Business Text Messaging
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Contact Sales
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to={Routes.HOME} className="link top-link">
                                        Partners
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Become a Partner
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Hire an Expert
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Integrations
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Marketplace
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Developer Portal
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to={Routes.HOME} className="link top-link">
                                        Company
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Contact
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Careers
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Press
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Partners
                                    </Link>
                                </li>
                                <li>
                                    <Link to={Routes.HOME} className="link">
                                        Legal
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="headquarters">
                            <div className="headquarter">
                                <div className="title">
                                    <SvgIcon id="icon-us-flag" className="flag" />
                                    <span>US Headquarters</span>
                                </div>
                                <div className="address">
                                    1650 W Digital, UT Lehi 84043
                                    Contact sales: 801.438.4425
                                </div>
                            </div>
                            <div className="headquarter">
                                <div className="title">
                                    <SvgIcon id="icon-eu-flag" className="flag" />
                                    <span>EU Headquarters</span>
                                </div>
                                <div className="address">
                                    Level 3, Queen and Collins, 100 Queen Street
                                    VIC Melbourne 3000
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles.footerCopyrightLoginPage}>
                <div className="copyright">© CleanWhale Inc. 2023</div>
                <div className="documents">
                    <Link to={Routes.HOME} className="flickr">
                        Sitemap
                    </Link>
                    <Link to={Routes.HOME} className="flickr">
                        Terms & Conditions
                    </Link>
                    <Link to={Routes.HOME} className="flickr">
                        Privacy
                    </Link>
                    <Link to={Routes.HOME} className="flickr">
                        Vulnerability Policy
                    </Link>
                    <Link to={Routes.HOME} className="flickr">
                        Do Not Sell or Share My Personal Information
                    </Link>
                </div>
            </section>
        </>
    );
};

export default Footer;